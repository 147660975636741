import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import { Spinner } from 'reactstrap';

//const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info'];

/**
 * Renders the preloader
 */
class PreLoaderWidget extends Component {

    render() {
        return (
            <div className="preloader">
                <div className="status">
                    {/* {colors.map((color, index) => {
                    return <Spinner key={index} className="m-1" type="grow" color={color} />;
                    })} */}
                    <Loader
                        type="Puff"
                        height={80}
                        width={80}
                    />
                </div>
            </div>
        )
    }
}

export default PreLoaderWidget;