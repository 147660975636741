/**
 * Entry application component used to compose providers and render Routes.
 * */

 import React from "react";
 import { Provider } from "react-redux";
 import { BrowserRouter } from "react-router-dom";
 import { PersistGate } from "redux-persist/integration/react";
 import  Routes from "./routes/Routes";
 import './App.css';
 import './assets/scss/theme.scss';
 import ClearCache from "react-clear-cache"; 
 
 export default function App({ store, persistor, basename }) {
  var reloaded = false;
  const toggle = () => {
    reloaded = true;
  }
   return (
     /* Provide Redux store */
      <Provider store={store}>
        <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <>
              {
                !isLatestVersion && !reloaded && emptyCacheStorage(), toggle() // eslint-disable-line
              }
            </>
          )}
        </ClearCache>
       {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
       <PersistGate persistor={persistor}>
         {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
         <React.Suspense>
           {/* Override `basename` (e.g: `homepage` in `package.json`) */}
           <BrowserRouter basename={basename}>
             {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
               {/* Provide `react-intl` context synchronized with Redux state.  */}
                 {/* Render routes with provided `Layout`. */}
                 <Routes />
           </BrowserRouter>
         </React.Suspense>
       </PersistGate>
     </Provider>
   );
 }
 