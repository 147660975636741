export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken, user }
      } = store.getState();
      config.headers["Accept-Language"] = "en";
      config.headers["Content-Type"] = "text/xml"
      // config.headers["SOAPAction"] = "http://tempuri.org/"
      if (authToken) {
        //config.headers.Authorization = `Bearer ${authToken}`;
        // if (user) {
          // config.params = { orgGUID: "2ED23166-12B6-4B6D-824C-55D9A24B04A7" };
        // }
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    next => {
      return Promise.resolve(next);
    },
    error => {
      // You can handle error here and trigger warning message without get in the code inside
      if (error && error?.response?.status === 401) {
        if(window.location.pathname!=="/auth/login")
        {
        window.location = "/logout";
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}
