import { createSlice } from "@reduxjs/toolkit";

let test = [];
test.push(new Date());
const initialSearchState = {
  actionsLoading: false,
  success: false,
  failure: false,
  errorMessage: undefined,
  successMessage: undefined,
  multiIndex: 0,
  searchMulti: {
    fromDate: new Date(),
  },
  searchMultiList: test,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const searchSlice = createSlice({
  name: "search",
  initialState: initialSearchState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
      state.failure = true;
      state.success = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    // Update state
    updateState: (state) => {
      state.success = false;
      state.failure = false;
    },
    updateMultiIndex: (state, action) => {
      state.multiIndex = action.payload;
    },
    updateMessage: (state) => {
      state.errorMessage = null;
      state.successMessage = null;
      state.failure = false;
      state.success = false;
    },
    updateDate: (state, action) => {
      state.searchMultiList = action.payload;
    },
  },
});
