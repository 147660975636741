import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from '../pages/auth/_redux/authRedux';
import {searchSlice} from '../pages/Search/_redux/searchSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  search: searchSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
