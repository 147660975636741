import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { Provider } from 'react-redux'
import store, { persistor } from "./redux/store";
import * as _redux from "./redux";
import * as serviceWorker from './serviceWorker';


const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    {/* </Provider>, */}
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();

